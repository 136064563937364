import { Box, BoxProps } from '../Primitives/components/Box';
import { breakPoints } from '../sprinkles.css';

export type Image = { src: string; alt: string; title?: string };

export interface PictureProps extends BoxProps<'picture'> {
  image: { default: Image; tabletSrc?: string; desktopSrc?: string };
  imgProps?: BoxProps<'img'>;
  disableLazy?: boolean;
}

export const Picture = ({ image, style, imgProps, disableLazy, ...props }: PictureProps) => {
  return (
    <Box
      element="picture"
      flex="1"
      flexShrink="0"
      // Optional props
      {...{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'full',
        width: 'full',
        borderRadius: 'base',
        backgroundColor: 'gray',
        overflow: 'hidden',
        boxShadow: 'base',
        style: { objectFit: 'contain', ...style },
        ...props,
      }}
    >
      {image.desktopSrc && (
        <source media={`(min-width: ${breakPoints.desktop}px)`} srcSet={`${image.desktopSrc}`} />
      )}
      {image.tabletSrc && (
        <source media={`(min-width: ${breakPoints.tablet}px)`} srcSet={`${image.tabletSrc}`} />
      )}
      <Box
        element="img"
        src={image.default.src}
        alt={image.default.alt}
        title={image.default.title}
        flexShrink="0"
        height="full"
        width="full"
        // Optional props
        {...{
          background: 'gray',
          style: {
            display: 'block',
            objectFit: 'contain',
          },
          loading: disableLazy ? undefined : 'lazy',
          ...imgProps,
        }}
      />
    </Box>
  );
};
