import * as React from 'react';

import { Container } from '../Design';
import { ContainerProps } from '../Design/Primitives/components/Container';

interface InPageContainerProps extends ContainerProps<'div'> {
  children: React.ReactNode;
  size?: ContainerProps<'div'>['size'];
}

const InPageContainer = ({ children, size, ...props }: InPageContainerProps) => {
  return (
    <Container paddingX={{ mobile: '16px', tablet: '28px' }} size={size} {...props}>
      {children}
    </Container>
  );
};

export default InPageContainer;
