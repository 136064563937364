import { getInstagramRefreshToken } from '../instagram/refresh-token';
import { BASE_URL, apiError } from '../utils';
import { fetchContentfulContentData } from '../utils/contentful';
import { updateContentfulInstagramTokenEntry } from './update-Instagram-access-token';

export const config = {
  runtime: 'edge',
};

const contentfulInstagramToken = async () => {
  try {
    const getEntry = await fetchContentfulContentData({ query: 'instagramToken' });

    const { instagramAccessToken } = getEntry;

    if (instagramAccessToken?.accessToken && instagramAccessToken?.accessToken !== 'undefined') {
      if (new Date(instagramAccessToken?.expirationDate) > new Date()) {
        return new Response(JSON.stringify(instagramAccessToken?.accessToken), {
          status: 200,
          headers: {
            'content-type': 'application/json',
            'cache-control': 's-maxage=86400   stale-while-revalidate=60',
          },
        });
      }

      const newToken = await getInstagramRefreshToken(instagramAccessToken?.accessToken);

      await updateContentfulInstagramTokenEntry(newToken);

      return new Response(JSON.stringify(newToken), {
        status: 200,
        headers: {
          'content-type': 'application/json',
          'cache-control': 's-maxage=86400   stale-while-revalidate=60',
        },
      });
    } else if (process.env.INSTAGRAM_USER_TOKEN) {
      const newToken = await getInstagramRefreshToken(process.env.INSTAGRAM_USER_TOKEN);

      await updateContentfulInstagramTokenEntry(newToken);

      return new Response(JSON.stringify(newToken), {
        status: 200,
        headers: {
          'content-type': 'application/json',
          'cache-control': 's-maxage=86400   stale-while-revalidate=60',
        },
      });
    }
  } catch (error) {
    apiError({ errorInText: 'get instagram token', error, isEdge: true });
  }
};

export const getContentfulInstagramToken = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/contentful/get-instagram-token`);

    return await response.json();
  } catch (e: any) {
    console.error('getInstagramToken failed', e);
    if (e) return e;
  }
};

export default contentfulInstagramToken;

// function that returns a string?
